import React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';

import Container from "components/Container";

const PrivacyPolicy = () => {
  return (
  <Grid item xs={12} md={9}>
    <Container paddingX={{xs: 2, sm: 4}}>

        <Box marginBottom={2}>
          <Typography>This privacy policy describes our policies and procedures on collecting, using, and disclosing your information when you use our website and tells you about your privacy rights and how the law protects you.
          </Typography>
        </Box>

        <Box marginBottom={2}>
          <Typography>We use your personal data to provide and improve our website. By using our website, you agree to the collection and use of information under this Privacy Policy.
          </Typography>
        </Box>

        <Box marginTop={4} marginBottom={2}>
          <Typography sx={{ fontWeight: 600 }} variant={"h4"}>
            Interpretation and Definitions
          </Typography>
        </Box>

        <Box>
          <Typography sx={{ fontWeight: 600 }} variant={"h6"}>
            Interpretation
          </Typography>
        </Box>

        <Box marginBottom={2}>
          <Typography>
            The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
          </Typography>
        </Box>

        <Box>
          <Typography sx={{ fontWeight: 600 }} variant={"h6"}>
            Definitions
          </Typography>
          For the purposes of this Privacy Policy:
        </Box>

        <Box>
          {/* List ITEM EXAMPLE <Box marginLeft={3} marginTop={1}><strong></strong>{' '}</Box> */}

          <Box marginLeft={3} marginTop={1}>
            <strong>Account:</strong>
            {"  "}
            means a unique account created for You to access our Service or
            parts of our Service.
          </Box>

          <Box marginLeft={3} marginTop={1}>
            <strong>Company:</strong>
            {"  "}
            (referred to as either &quot;the Company&quot;, &quot;We&quot;,
            &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to Kate
            Mills Company LLC, 2472 Balfour Ct.
          </Box>

          <Box marginLeft={3} marginTop={1}>
            <strong>Cookies:</strong>
            {"  "}
            are small files that are placed on Your computer, mobile device or
            any other device by a website, containing the details of Your
            browsing history on that website among its many uses.
          </Box>

          <Box marginLeft={3} marginTop={1}>
            <strong>Country:</strong>
            {"  "}
            refers to: California, United States
          </Box>

          <Box marginLeft={3} marginTop={1}>
            <strong>Device:</strong>
            {"  "}
            means any device that can access the Service such as a computer, a
            cellphone or a digital tablet.
          </Box>

          <Box marginLeft={3} marginTop={1}>
            <strong>Personal Data:</strong>
            {"  "}
            is any information that relates to an identified or identifiable
            individual.
          </Box>

          <Box marginLeft={3} marginTop={1}>
            <strong>Service:</strong> refers to the Website.
          </Box>

          <Box marginLeft={3} marginTop={1}>
            <strong>Service Provider:</strong> means any natural or legal person
            who processes the data on behalf of the Company. It refers to
            third-party companies or individuals employed by the Company to
            facilitate the Service, to provide the Service on behalf of the
            Company, to perform services related to the Service or to assist the
            Company in analyzing how the Service is used.
          </Box>

          <Box marginLeft={3} marginTop={1}>
            <strong>Usage Data:</strong> refers to data collected automatically,
            either generated by the use of the Service or from the Service
            infrastructure itself (for example, the duration of a page visit).
          </Box>

          <Box marginLeft={3} marginTop={1}>
            <strong>Website:</strong> refers to Ally Digital Solutions,
            accessible from{" "}
            <Link
              component={"a"}
              href="https://allydigitalsolutions.com"
              rel="external nofollow noopener"
              target="_blank"
            >
              https://allydigitalsolutions.com
            </Link>
          </Box>

          <Box marginLeft={3} marginTop={1}>
            <strong>You:</strong> means the individual accessing or using the
            Service, or the company, or other legal entity on behalf of which
            such individual is accessing or using the Service, as applicable.
            <br />
            Under GDPR (General Data Protection Regulation), You can be referred
            to as the Data Subject or as the User as you are the individual
            using the Service.
          </Box>
        </Box>

        <Box marginTop={5} id="data-policy">
          <Typography variant="overline">Data Policy</Typography>
        </Box>

        <Box>
          <Typography
            sx={{ fontWeight: 600 }}
            variant={"h4"}
            marginTop={4}
            marginBottom={1}
          >
            Collecting and Using Your Personal Data
          </Typography>
          <Typography sx={{ fontWeight: 600 }} variant={"h6"}>
            Types of Data Collected
          </Typography>
          <Box marginTop={2}>
            <Typography sx={{ fontWeight: 600 }} variant={"subtitle1"}>
              Personal Data
            </Typography>
            <Box>
              While using Our Service, We may ask You to provide Us with certain
              personally identifiable information that can be used to contact or
              identify You. Personally identifiable information may include, but
              is not limited to:
            </Box>
            <Box>
              <Typography marginLeft={3} marginTop={2} component={"li"}>
                First name
              </Typography>
              <Typography marginLeft={3} marginTop={1} component={"li"}>
                Last name
              </Typography>
              <Typography marginLeft={3} marginTop={1} component={"li"}>
                Email
              </Typography>
              <Typography marginLeft={3} marginTop={1} component={"li"}>
                Usage Data
              </Typography>
            </Box>
          </Box>
          <Box marginTop={2}>
            <Typography sx={{ fontWeight: 600 }} variant={"subtitle1"}>
              Usage Data
            </Typography>
            <Box>
              <Typography marginBottom={2}>
                Usage Data is collected automatically when using the Service.
              </Typography>
              <Typography marginBottom={2}>
                Usage Data may include information such as, Your Device's
                Internet Protocol address (e.g. IP address), browser type,
                browser version, the pages of our Service that You visit, the
                time and date of Your visit, the time spent on those pages,
                unique device identifiers and other diagnostic data.
              </Typography>

              <Typography marginBottom={2}>
                When You access the Service by or through a mobile device, We
                may collect certain information automatically, including, but
                not limited to, the type of mobile device You use, Your mobile
                device unique ID, the IP address of Your mobile device, Your
                mobile operating system, the type of mobile Internet browser You
                use, unique device identifiers and other diagnostic data.
              </Typography>

              <Typography marginBottom={2}>
                We may also collect information that Your browser sends whenever
                You visit our Service or when You access the Service by or
                through a mobile device.
              </Typography>
            </Box>
          </Box>
          <Box marginTop={5} id="cookie-policy">
            <Typography variant="overline">Cookie Policy</Typography>
          </Box>
          <Box>
            <Typography
              sx={{ fontWeight: 600 }}
              variant={"h6"}
              marginTop={4}
              marginBottom={1}
            >
              Tracking Technologies and Cookies
            </Typography>
            <Box>
              We use Cookies and similar tracking technologies to track the
              activity on Our Service and store certain information. Tracking
              technologies used are beacons, tags, and scripts to collect and
              track information and to improve and analyze Our Service. The
              technologies We use may include:
            </Box>
          </Box>
          <Box>
            {/* List ITEM EXAMPLE
              <Box marginLeft={3} marginTop={1}><Typography sx={{fontWeight:600, display:'inline'}}></Typography>{' '}</Box>
            */}

            {/*START Cookies or Browser Cookies*/}
            <Box marginLeft={3} marginTop={1}>
              <Typography
                sx={{ fontWeight: 600, display: "inline" }}
                variant={"subtitle2"}
              >
                Cookies or Browser Cookies.
              </Typography>

              <Typography sx={{ display: "inline" }} paddingLeft={1}>
                A cookie is a small file placed on Your Device. You can instruct
                Your browser to refuse all Cookies or to indicate when a Cookie
                is being sent. However, if You do not accept Cookies, You may
                not be able to use some parts of our Service. Unless you have
                adjusted Your browser setting so that it will refuse Cookies,
                our Service may use Cookies.
              </Typography>
            </Box>
            {/*END Cookies or Browser Cookies*/}

            {/*START Web Beacons */}
            <Box marginLeft={3} marginTop={1}>
              <Typography
                sx={{ fontWeight: 600, display: "inline" }}
                variant={"subtitle2"}
              >
                Web Beacons.
              </Typography>
              <Typography sx={{ display: "inline" }} paddingLeft={1}>
                Certain sections of our Service and our emails may contain small
                electronic files known as web beacons (also referred to as clear
                gifs, pixel tags, and single-pixel gifs) that permit the
                Company, for example, to count users who have visited those
                pages or opened an email and for other related website
                statistics (for example, recording the popularity of a certain
                section and verifying system and server integrity).
              </Typography>
            </Box>
          </Box>
          <Box marginTop={1}>
            Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
            remain on Your personal computer or mobile device when You go
            offline, while Session Cookies are deleted as soon as You close Your
            web browser.
          </Box>
          <Box marginTop={1}>
            We use both Session and Persistent Cookies for the purposes set out
            below:
          </Box>
          <Box component={"ul"}>
            <Box
              marginLeft={3}
              marginTop={1}
              component={"li"}
              sx={{ fontWeight: 600 }}
            >
              Necessary / Essential Cookies
            </Box>
            <Box marginLeft={4}>Type: Session Cookies</Box>
            <Box marginLeft={4}>Administered by: Us</Box>
            <Box marginLeft={4}>
              Purpose: These Cookies are essential to provide You with services
              available through the Website and to enable You to use some of its
              features. They help to authenticate users and prevent fraudulent
              use of user accounts. Without these Cookies, the services that You
              have asked for cannot be provided, and We only use these Cookies
              to provide You with those services.
            </Box>

            <Box
              marginTop={2}
              marginLeft={3}
              component={"li"}
              sx={{ fontWeight: 600 }}
            >
              Cookies Policy / Notice Acceptance Cookies
            </Box>
            <Box marginLeft={4}>Type: Persistent Cookies</Box>
            <Box marginLeft={4}>Administered by: Us</Box>
            <Box marginLeft={4}>
              Purpose: These Cookies identify if users have accepted the use of
              cookies on the Website.
            </Box>

            <Box
              marginTop={2}
              marginLeft={3}
              component={"li"}
              sx={{ fontWeight: 600 }}
            >
              Functionality Cookies
            </Box>
            <Box marginLeft={4}>Type: Persistent Cookies</Box>
            <Box marginLeft={4}>Administered by: Us</Box>
            <Box marginLeft={4}>
              Purpose: These Cookies allow us to remember choices You make when
              You use the Website, such as remembering your login details or
              language preference. The purpose of these Cookies is to provide
              You with a more personal experience and to avoid You having to
              re-enter your preferences every time You use the Website.
            </Box>

            <Box
              marginLeft={3}
              marginTop={2}
              component={"li"}
              sx={{ fontWeight: 600 }}
            >
              Tracking and Performance Cookies
            </Box>
            <Box marginLeft={4} marginTop={1} sx={{ fontWeight: 400 }}>
              Type: Persistent Cookies
            </Box>
            <Box marginLeft={4} marginTop={1} sx={{ fontWeight: 400 }}>
              Administered by: Third-Parties
            </Box>
            <Box marginLeft={4} marginTop={1} sx={{ fontWeight: 400 }}>
              Purpose: These Cookies are used to track information about traffic
              to the Website and how users use the Website. The information
              gathered via these Cookies may directly or indirectly identify you
              as an individual visitor. This is because the information
              collected is typically linked to a pseudonymous identifier
              associated with the device you use to access the Website. We may
              also use these Cookies to test new pages, features or new
              functionality of the Website to see how our users react to them.
            </Box>

            <Typography marginTop={1} variant={"body2"}>
              For more information about the cookies we use and your choices
              regarding cookies, please visit our Cookies Policy or the Cookies
              section of our Privacy Policy.
            </Typography>
          </Box>{" "}
          {/*END OF COOKIES LIST*/}
          <Box>
            {/*USE OF YOUR PERSONAL DATA*/}
            <Box>
              <Typography
                sx={{ fontWeight: 600 }}
                variant={"h5"}
                marginTop={4}
                marginBottom={1}
              >
                Use of Your Personal Data
              </Typography>
              <Typography marginBottom={1}>
                The Company may use Personal Data for the following purposes:
              </Typography>

              <Box marginLeft={3} marginTop={1}>
                {" "}
                <Typography
                  sx={{ fontWeight: 600, display: "inline" }}
                  paddingRight={1}
                >
                  {" "}
                  To provide and maintain our Service,{" "}
                </Typography>
                including to monitor the usage of our Service.{" "}
              </Box>
              <Box marginLeft={3} marginTop={1}>
                <Typography
                  sx={{ fontWeight: 600, display: "inline" }}
                  paddingRight={1}
                >
                  To manage Your account:
                </Typography>
                to manage Your registration as a user of the Service. The
                Personal Data You provide can give You access to different
                functionalities of the Service that are available to You as a
                registered user.
              </Box>
              <Box marginLeft={3} marginTop={1}>
                <Typography
                  sx={{ fontWeight: 600, display: "inline" }}
                  paddingRight={1}
                >
                  For the performance of a contract:
                </Typography>
                the development, compliance and undertaking of the purchase
                contract for the products, items or services You have purchased
                or of any other contract with Us through the Service.
              </Box>
              <Box marginLeft={3} marginTop={1}>
                <Typography
                  sx={{ fontWeight: 600, display: "inline" }}
                  paddingRight={1}
                >
                  To contact You:
                </Typography>
                To contact You by email, telephone calls, SMS, or other
                equivalent forms of electronic communication, such as a mobile
                application's push notifications regarding updates or
                informative communications related to the functionalities,
                products or contracted services, including the security updates,
                when necessary or reasonable for their implementation.
              </Box>
              <Box marginLeft={3} marginTop={1}>
                <Typography
                  sx={{ fontWeight: 600, display: "inline" }}
                  paddingRight={1}
                >
                  To provide You
                </Typography>
                with news, special offers and general information about other
                goods, services and events which we offer that are similar to
                those that you have already purchased or enquired about unless
                You have opted not to receive such information.
              </Box>
              <Box marginLeft={3} marginTop={1}>
                <Typography
                  sx={{ fontWeight: 600, display: "inline" }}
                  paddingRight={1}
                >
                  To manage Your requests:
                </Typography>
                To attend and manage Your requests to Us.
              </Box>
              <Box marginLeft={3} marginTop={1}>
                <Typography
                  sx={{ fontWeight: 600, display: "inline" }}
                  paddingRight={1}
                >
                  For business transfers:
                </Typography>
                We may use Your information to evaluate or conduct a merger,
                divestiture, restructuring, reorganization, dissolution, or
                other sale or transfer of some or all of Our assets, whether as
                a going concern or as part of bankruptcy, liquidation, or
                similar proceeding, in which Personal Data held by Us about our
                Service users is among the assets transferred.
              </Box>
              <Box marginLeft={3} marginTop={1}>
                <Typography
                  sx={{ fontWeight: 600, display: "inline" }}
                  paddingRight={1}
                >
                  For other purposes
                </Typography>
                We may use Your information for other purposes, such as data
                analysis, identifying usage trends, determining the
                effectiveness of our promotional campaigns and to evaluate and
                improve our Service, products, services, marketing and your
                experience.
              </Box>
            </Box>

            <Box marginTop={2}>
              <Typography marginBottom={1}>
                We may share Your personal information in the following
                situations:
              </Typography>
              <Box marginLeft={3} marginTop={1}>
                <Typography
                  sx={{ fontWeight: 600, display: "inline" }}
                  paddingRight={1}
                >
                  With Service Providers:
                </Typography>
                We may share Your personal information with Service Providers to
                monitor and analyze the use of our Service, to contact You.
              </Box>

              <Box marginLeft={3} marginTop={1}>
                <Typography
                  sx={{ fontWeight: 600, display: "inline" }}
                  paddingRight={1}
                >
                  For business transfers:
                </Typography>
                We may share or transfer Your personal information in connection
                with, or during negotiations of, any merger, sale of Company
                assets, financing, or acquisition of all or a portion of Our
                business to another company.
              </Box>

              <Box marginLeft={3} marginTop={1}>
                <Typography
                  sx={{ fontWeight: 600, display: "inline" }}
                  paddingRight={1}
                >
                  With Affiliates:
                </Typography>
                We may share Your information with Our affiliates, in which case
                we will require those affiliates to honor this Privacy Policy.
                Affiliates include Our parent company and any other
                subsidiaries, joint venture partners or other companies that We
                control or that are under common control with Us.
              </Box>

              <Box marginLeft={3} marginTop={1}>
                <Typography
                  sx={{ fontWeight: 600, display: "inline" }}
                  paddingRight={1}
                >
                  With business partners:
                </Typography>
                We may share Your information with Our business partners to
                offer You certain products, services or promotions.
              </Box>

              <Box marginLeft={3} marginTop={1}>
                <Typography
                  sx={{ fontWeight: 600, display: "inline" }}
                  paddingRight={1}
                >
                  With other users
                </Typography>
                When You share personal information or otherwise interact in the
                public areas with other users, such information may be viewed by
                all users and may be publicly distributed outside.
              </Box>
              <Box marginLeft={3} marginTop={1}>
                <Typography
                  sx={{ fontWeight: 600, display: "inline" }}
                  paddingRight={1}
                >
                  With Your consent
                </Typography>
                We may disclose Your personal information for any other purpose
                with Your consent.
              </Box>
            </Box>
          </Box>
          <Box>
            {" "}
            {/* RETENTION OF YOUR PERSONAL DATA */}
            <Typography sx={{ fontWeight: 600 }} variant={"h6"} marginTop={2}>
              Retention of Your Personal Data
            </Typography>
            <Typography marginLeft={2} marginBottom={1}>
              The Company will retain Your Personal Data only for as long as is
              necessary for the purposes set out in this Privacy Policy. We will
              retain and use Your Personal Data to the extent necessary to
              comply with our legal obligations (for example, if we are required
              to retain your data to comply with applicable laws), resolve
              disputes, and enforce our legal agreements and policies.
            </Typography>
            <Typography marginLeft={2} marginBottom={1}>
              The Company will also retain Usage Data for internal analysis
              purposes. Usage Data is generally retained for a shorter period of
              time, except when this data is used to strengthen the security or
              to improve the functionality of Our Service, or We are legally
              obligated to retain this data for longer time periods.
            </Typography>
          </Box>
          <Box>
            {/* TRANSFER OF YOUR PERSONAL DATA*/}
            <Typography sx={{ fontWeight: 600 }} variant={"h6"} marginTop={2}>
              Transfer of Your Personal Data
            </Typography>
            <Typography marginLeft={2} marginBottom={1}>
              Your information, including Personal Data, is processed at the
              Company's operating offices and in any other places where the
              parties involved in the processing are located. It means that this
              information may be transferred to — and maintained on — computers
              located outside of Your state, province, country or other
              governmental jurisdiction where the data protection laws may
              differ than those from Your jurisdiction.
            </Typography>
            <Typography marginLeft={2} marginBottom={1}>
              Your consent to this Privacy Policy followed by Your submission of
              such information represents Your agreement to that transfer.
            </Typography>
            <Typography marginLeft={2} marginBottom={1}>
              The Company will take all steps reasonably necessary to ensure
              that Your data is treated securely and in accordance with this
              Privacy Policy and no transfer of Your Personal Data will take
              place to an organization or a country unless there are adequate
              controls in place including the security of Your data and other
              personal information.
            </Typography>
          </Box>
          <Box>
            {" "}
            {/*DISCLOSURE OF YOUR PERSONAL DATA */}
            <Typography
              sx={{ fontWeight: 600 }}
              variant={"h5"}
              marginTop={4}
              marginBottom={1}
            >
              Disclosure of Your Personal Data
            </Typography>
            <Box>
              <Typography sx={{ fontWeight: 600 }} variant={"h6"}>
                Business Transactions
              </Typography>
              <Typography marginLeft={2} marginBottom={1}>
                If the Company is involved in a merger, acquisition or asset
                sale, Your Personal Data may be transferred. We will provide
                notice before Your Personal Data is transferred and becomes
                subject to a different Privacy Policy.
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontWeight: 600 }} variant={"h6"}>
                Law enforcement
              </Typography>
              <Typography marginLeft={2} marginBottom={1}>
                Under certain circumstances, the Company may be required to
                disclose Your Personal Data if required to do so by law or in
                response to valid requests by public authorities (e.g. a court
                or a government agency).
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontWeight: 600 }} variant={"h6"}>
                Other legal requirements
              </Typography>
              <Typography marginLeft={2} marginBottom={1}>
                The Company may disclose Your Personal Data in the good faith
                belief that such action is necessary to:
              </Typography>

              <Box marginLeft={3} marginTop={1}>
                Comply with a legal obligation
              </Box>
              <Box marginLeft={3} marginTop={1}>
                Protect and defend the rights or property of the Company
              </Box>
              <Box marginLeft={3} marginTop={1}>
                Prevent or investigate possible wrongdoing in connection with
                the Service
              </Box>
              <Box marginLeft={3} marginTop={1}>
                Protect the personal safety of Users of the Service or the
                public
              </Box>
              <Box marginLeft={3} marginTop={1}>
                Protect against legal liability
              </Box>
            </Box>
          </Box>
        </Box>
        <Box>
          <Typography
            sx={{ fontWeight: 600 }}
            variant={"h5"}
            marginTop={4}
            marginBottom={1}
          >
            Security of Your Personal Data
          </Typography>
          <Typography marginLeft={2} marginBottom={1}>
            The security of Your Personal Data is important to Us, but remember
            that no method of transmission over the Internet, or method of
            electronic storage is 100% secure. While We strive to use
            commercially acceptable means to protect Your Personal Data, We
            cannot guarantee its absolute security.
          </Typography>
        </Box>

        <Box>
          <Typography
            sx={{ fontWeight: 600 }}
            variant={"h4"}
            marginTop={4}
            marginBottom={1}
          >
            Children's Privacy
          </Typography>

          <Typography marginBottom={1}>
            Our Service does not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from anyone
            under the age of 13. If You are a parent or guardian and You are
            aware that Your child has provided Us with Personal Data, please
            contact Us. If We become aware that We have collected Personal Data
            from anyone under the age of 13 without verification of parental
            consent, We take steps to remove that information from Our servers.
          </Typography>

          <Typography marginBottom={1}>
            If We need to rely on consent as a legal basis for processing Your
            information and Your country requires consent from a parent, We may
            require Your parent's consent before We collect and use that
            information.
          </Typography>
        </Box>

        <Box>
          <Typography
            sx={{ fontWeight: 600 }}
            variant={"h4"}
            marginTop={4}
            marginBottom={1}
          >
            Links to Other Websites
          </Typography>

          <Typography marginBottom={1}>
            Our Service may contain links to other websites that are not
            operated by Us. If You click on a third party link, You will be
            directed to that third party's site. We strongly advise You to
            review the Privacy Policy of every site You visit.
          </Typography>
          <Typography>
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </Typography>
        </Box>

        <Box marginTop={2}>
          <Typography sx={{ fontWeight: 600 }} variant={"h4"} marginBottom={1}>
            Changes to this Privacy Policy
          </Typography>
          <Typography>
            We may update Our Privacy Policy from time to time. We will notify
            You of any changes by posting the new Privacy Policy on this page.
          </Typography>
          <Typography>
            We will let You know via email and/or a prominent notice on Our
            Service, prior to the change becoming effective and update the
            &quot;Last updated&quot; date at the top of this Privacy Policy.
          </Typography>

          <Typography>
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </Typography>
        </Box>
    </Container>
      </Grid>
  );
};

export default PrivacyPolicy;

//LIST ITEM <Box marginLeft={3} marginTop={1}><Typography sx={{fontWeight:600, display:'inline', }} paddingRight={1}>BOLD</Typography>EXTRA TEXT</Box>

